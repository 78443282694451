import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { ModalComponent } from '@shared/components/modals/modal.component';

@Component({
  selector: 'app-log-out-modal',
  standalone: true,
  imports: [ModalComponent],
  templateUrl: './log-out-modal.component.html',
  styleUrl: './log-out-modal.component.scss'
})
export class LogOutModalComponent {

  constructor(private authService: MsalService, public dialog: MatDialogRef<ModalComponent>,) {}

  public closeModal() {
    this.dialog.close();
  }

  public async logOut() { 
    try {
      await this.authService.logoutRedirect();
    } catch (error) {
      console.error('Logout error', error);
    }
  }

}
