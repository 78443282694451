<div class="modal-content">
    <app-modal [title]="'Log Out'">
        <div class="modal-conteiner">
            <div class="modal-body">
                <p>Are you sure you want to log out?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn-logout" (click)="logOut()">Log Out</button>
                <button type="button" class="btn-cancel" data-dismiss="modal" (click)="closeModal()">Cancel</button>
            </div>
        </div>
    </app-modal>
</div>
